import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import SalesTypeSelectButton from '@/components/Common/Button/SalesTypeSelectButton';
import MenuHeader from '@/components/Header/MenuHeader';
import MyListing from '@/components/Products/Sale/MyListing';
import { PRODUCT_TYPE_DIRECT, PRODUCT_TYPE_SPEED } from '@/const/products';
import metadata from '@/metadatas/metadata';
import { PageMetas } from '@/metadatas/metadatas';

const MyCarSaleMain = () => {
  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.setItem('entryPoint', location.pathname);
  }, []);

  const handleClickSalesType = (type: string) => {
    navigate('/sell-car/guide', { state: { type: type } });
  };

  const handleClickSaleHelp = () => {
    navigate('/one-stop-service');
  };

  return (
    <>
      <PageMetas {...metadata.sale}></PageMetas>
      <div className="flex flex-col justify-between w-full">
        <MenuHeader title="내차판매" isMain></MenuHeader>

        <div className="pt-[60px]">
          <h2 className="text-[30px] font-bold text-gray-8 leading-[130%] px-4">
            판매 방식을
            <br />
            선택해주세요
          </h2>
          <SalesTypeSelectButton
            onClickLeft={() => handleClickSalesType(PRODUCT_TYPE_DIRECT)}
            onClickRight={() => handleClickSalesType(PRODUCT_TYPE_SPEED)}
          />
        </div>
        <div
          className="mt-[30px] text-center font-medium text-[14px] text-gray-7 underline underline-offset-2"
          onClick={handleClickSaleHelp}
        >
          차량 판매에 어려움이 있으신가요?
        </div>
        <div className="pb-16">
          <MyListing />
        </div>
      </div>
    </>
  );
};

export default MyCarSaleMain;
